<template>
  <div class="login-container">
    <div class="header-box" />
    <!-- 登录界面 -->
    <div class="sign-in">
      <el-form ref="userInfo" :rules="rules" :model="userInfo">
        <el-form-item prop="phone">
          <el-input v-model="userInfo.phone" class="sign-input" type="text" placeholder="手机号码">
            <svg-icon
              slot="prefix"
              style="margin-top: 15px; "
              name="phone"
              color="#1d1d1f"
              width="18"
              height="18"
            />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="userInfo.password" class="sign-input top" type="password" placeholder="密码">
            <svg-icon
              slot="prefix"
              style="margin-top: 15px; "
              name="password"
              color="#1d1d1f"
              width="18"
              height="18"
            />
            <div slot="suffix" class="forget-pwd" @click="resetAccount">忘记密码？</div>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="sign-button" @click="login('userInfo')">
            <span class="btn-text">登录</span>
          </el-button>
        </el-form-item>
        <div class="register-notice">
          我没有账号，
          <span class="register" @click="registerAccount">注册新账号</span>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import '@/icons/phone'
import '@/icons/password'
import '@/icons/idcode'
// import {sendLogin} from "@/api/login";

export default {
  data() {
    return {
      userInfo: {
        phone: '',
        password: ''
      },
      rules: {
        phone: [{ required: true, message: '请输入电话号码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      textColor: '#1d1d1f'
    }
  },
  methods: {
    // login
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loginData = {
            account: this.userInfo.phone,
            password: this.userInfo.password
          }
          this.$store.dispatch('user/login', { loginData }).then(() => {
            this.$message.success('登录成功！')
            this.$router.push('/')
          })
        } else {
          this.$message.warning('信息填写有误')
        }
      })
      console.log('login in')
    },
    // 重置账号
    resetAccount() {
      console.log('reset account')
      this.$router.push({ name: 'ResetPassword' })
    },
    // 进行账号注册
    registerAccount() {
      console.log('register acctoun')
      this.$router.push('register')
    }
  }
}
</script>

<style scoped>
.header-box {
  height: 60px;
}

.login-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* border: 1px solid white; */
  /* -webkit-box-align: center; */
}

.sign-in {
  position: relative;
  width: 300px;
  /* height: 200px; */
  margin: auto;
  /* -webkit-box-align: center; */
  color: white;
  /* border: 1px solid white; */
}

.top {
  margin-top: 20px;
}

.sign-input {
  /* position: relative; */
  /* height: 60px; */
  font-size: 16px;
  color: #1d1d1f;
}

.sign-input /deep/ .el-input__inner {
  border: none;
  height: 50px;
  border-bottom: 1px solid #1d1d1f;
  padding-left: 40px;
  border-radius: 0px;
  background-color: transparent;
  color: #1d1d1f;
}

.sign-button {
  width: 100%;
  /* font-weight: 800; */
  height: 50px;
  margin-top: 20px;
}

.btn-text {
  font-weight: 800;
}

.forget-pwd {
  font-size: 13px;
  margin-top: 5px;
}

.forget-pwd:hover {
  cursor: pointer;
}

.register-notice {
  margin-top: 23px;
  font-size: 13px;
  text-align: center;
  color: #1d1d1f;
}

.register {
  border-bottom: 1px solid #1d1d1f;
}

.register:hover {
  cursor: pointer;
}
</style>
